const videosActionTypes = {
  FETCH_VIDEOS_REQUEST: "FETCH_VIDEOS_REQUEST",
  FETCH_VIDEOS: "FETCH_VIDEOS",
  FETCH_VIDEOS_FULFILLED: "FETCH_VIDEOS_FULFILLED",
  FETCH_VIDEOS_REJECTED: "FETCH_VIDEOS_REJECTED",
  FETCH_UPDATE_VIDEOS_REQUEST: "FETCH_UPDATE_VIDEOS_REQUEST",
  FETCH_UPDATE_VIDEOS_SUCCESS: "FETCH_UPDATE_VIDEOS_SUCCESS",
  FETCH_UPDATE_VIDEOS_FAILED: "FETCH_UPDATE_VIDEOS_FAILED",
  FETCH_DELETE_VIDEOS_REQUEST: "FETCH_DELETE_VIDEOS_REQUEST",
  FETCH_DELETE_VIDEOS_SUCCESS: "FETCH_DELETE_VIDEOS_SUCCESS",
  FETCH_DELETE_VIDEOS_FAILED: "FETCH_DELETE_VIDEOS_FAILED",
  FETCH_EDIT_VIDEOS_REQUEST: "FETCH_EDIT_VIDEOS_REQUEST",
  FETCH_EDIT_VIDEOS_SUCCESS: "FETCH_EDIT_VIDEOS_SUCCESS",
  FETCH_EDIT_VIDEOS_FAILED: "FETCH_EDIT_VIDEOS_FAILED",
};

export default videosActionTypes;
