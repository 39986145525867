import { Breadcrumb, Button, Layout, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getVideosRequest } from "../../redux-state/videos/videos.action";
import ModalForm from "./modal/Modal";
import TableData from "./table/Table";

const { Content } = Layout;
const { Text } = Typography;

const Videos = (props) => {
  const { getVideosRequest, videosLoaded, videosData } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    getVideosRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getVideosRequest]);

  const tableDatas = [
    {
      title: "Id",
      dataIndex: "id",
      width: "10%",
      editable: false,
    },
    {
      title: "Video",
      dataIndex: "video",
      width: "20%",
      editable: true,
    },
    {
      title: "Link",
      dataIndex: "url",
      width: "20%",
      editable: true,
    },
    {
      title: "Category",
      dataIndex: "category",
      width: "20%",
      editable: true,
    },
    {
      title: "Show",
      dataIndex: "show",
      width: "10%",
      editable: true,
    },
  ];

  return (
    <Content
      style={{
        margin: "0 16px",
      }}
    >
      <Breadcrumb
        style={{
          margin: "16px 0",
        }}
      >
        <Breadcrumb.Item>Videos</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{
          padding: 24,
          minHeight: 360,
        }}
      >
        {message !== "" && message !== "Server connection failed" ? (
          <Text type="success">{message}</Text>
        ) : (
          <Text type="danger">{message}</Text>
        )}
        <Button
          //onClick={handleAdd}
          type="primary"
          onClick={showModal}
        >
          Add a video
        </Button>
        {videosLoaded ? <TableData tableDatas={tableDatas} /> : ""}
      </div>
      {isModalOpen ? (
        <ModalForm
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          videosData={videosData}
          setMessage={setMessage}
        />
      ) : (
        ""
      )}
    </Content>
  );
};

const mapStateToProps = (state) => {
  return {
    videosLoaded: state.videos.isLoaded,
    videosData: state.videos.videos,
  };
};
export default connect(mapStateToProps, {
  getVideosRequest,
})(Videos);
