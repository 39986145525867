import AboutActionTypes from "./about.types";

export const getAboutRequest = () => ({
  type: AboutActionTypes.FETCH_ABOUT_REQUEST,
});

export const getAboutSuccess = (data) => ({
  type: AboutActionTypes.FETCH_ABOUT,
  payload: data,
});

export const getAboutFailed = (error) => ({
  type: AboutActionTypes.FETCH_ABOUT,
  payload: error,
});

export const updateAboutRequest = (data) => ({
  type: AboutActionTypes.FETCH_UPDATE_ABOUT_REQUEST,
  payload: data,
});

export const updateAboutSuccess = (data) => ({
  type: AboutActionTypes.FETCH_UPDATE_ABOUT_SUCCESS,
  payload: data,
});

export const updateAboutFailed = (error) => ({
  type: AboutActionTypes.FETCH_UPDATE_ABOUT_FAILED,
  payload: error,
});
