import VideosActionTypes from "./videos.types";
import { editVideo } from "./videos.utils";

const INITIAL_STATE = {
  videos: [],
  isFetching: false,
  isLoaded: false,
  errorMessage: undefined,
};

const videosReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VideosActionTypes.FETCH_VIDEOS_REQUEST:
      return { ...state, isFetching: true, isLoaded: false };

    case VideosActionTypes.FETCH_VIDEOS_FULFILLED:
      return {
        ...state,
        videos: action.payload,
        isFetching: false,
        isLoaded: true,
      };

    case VideosActionTypes.FETCH_VIDEOS_REJECTED:
      return {
        ...state,
        errorMessage: action.payload,
        isFetching: false,
        isLoaded: false,
      };
    case VideosActionTypes.FETCH_UPDATE_VIDEOS_SUCCESS:
      return {
        ...state,
        videos: [...state.videos, action.payload],
        isFetching: false,
        isLoaded: true,
      };

    case VideosActionTypes.FETCH_UPDATE_VIDEOS_FAILED:
      return state;

    case VideosActionTypes.FETCH_DELETE_VIDEOS_SUCCESS:
      return {
        ...state,
        videos: action.payload,
        isFetching: false,
        isLoaded: true,
      };

    case VideosActionTypes.FETCH_DELETE_VIDEOS_FAILED:
      return state;

    case VideosActionTypes.FETCH_EDIT_VIDEOS_SUCCESS:
      return {
        ...state,
        videos: editVideo(state.videos, action.payload, action.id),
        isFetching: false,
        isLoaded: true,
      };

    case VideosActionTypes.FETCH_EDIT_VIDEOS_FAILED:
      return state;
    default:
      return state;
  }
};

export default videosReducer;
