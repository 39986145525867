import CategoriesActionTypes from "./categories.types";
import { editCategory } from "./categories.utils";

const INITIAL_STATE = {
  categories: [],
  isFetching: false,
  isLoaded: false,
  errorMessage: undefined,
};

const categoriesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CategoriesActionTypes.FETCH_CATEGORIES_REQUEST:
      return { ...state, isFetching: true, isLoaded: false };

    case CategoriesActionTypes.FETCH_CATEGORIES_FULFILLED:
      return {
        ...state,
        categories: action.payload,
        isFetching: false,
        isLoaded: true,
      };

    case CategoriesActionTypes.FETCH_CATEGORIES_REJECTED:
      return {
        ...state,
        errorMessage: action.payload,
        isFetching: false,
        isLoaded: false,
      };
    case CategoriesActionTypes.FETCH_UPDATE_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: [...state.categories, action.payload],
        isFetching: false,
        isLoaded: true,
      };

    case CategoriesActionTypes.FETCH_UPDATE_CATEGORIES_FAILED:
      return state;

    case CategoriesActionTypes.FETCH_DELETE_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        isFetching: false,
        isLoaded: true,
      };

    case CategoriesActionTypes.FETCH_DELETE_CATEGORIES_FAILED:
      return state;

    case CategoriesActionTypes.FETCH_EDIT_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: editCategory(state.categories, action.payload, action.id),
        isFetching: false,
        isLoaded: true,
      };

    case CategoriesActionTypes.FETCH_EDIT_CATEGORIES_FAILED:
      return state;

    default:
      return state;
  }
};

export default categoriesReducer;
