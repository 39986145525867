import { Breadcrumb, Layout } from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getFooterRequest } from "../../redux-state/footer/footer.action";
import FooterForm from "./components/FooterForm";

const { Content } = Layout;

const Footer = (props) => {
  const { getFooterRequest, footerLoaded } = props;
  useEffect(() => {
    getFooterRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFooterRequest]);

  return (
    <Content
      style={{
        margin: "0 16px",
      }}
    >
      <Breadcrumb
        style={{
          margin: "16px 0",
        }}
      >
        <Breadcrumb.Item>About Us</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{
          padding: 24,
          minHeight: 360,
          position: "relative",
        }}
      >
        {footerLoaded ? <FooterForm /> : ""}
      </div>
    </Content>
  );
};

const mapStateToProps = (state) => {
  return {
    footerLoaded: state.footer.isLoaded,
  };
};
export default connect(mapStateToProps, {
  getFooterRequest,
})(Footer);
