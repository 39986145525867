import { Navigate, Routes, Route } from "react-router-dom";
import {
  Categories,
  AboutUs,
  Footer,
  HeaderImages,
  Videos,
  Home,
} from "./pages";
import Login from "./pages/auth/login/Login";

export const Router = () => {
  return (
    <Routes>
      {/* FIXME: Update to / Navigate to login*/}
      <Route path="/" element={<Navigate to="dashboard" replace />} />
      <Route path="login" element={<Login />} />
      <Route path="dashboard" element={<Home />} />
      <Route path="categories" element={<Categories />} />
      <Route path="about" element={<AboutUs />} />
      <Route path="footer" element={<Footer />} />
      <Route path="header" element={<HeaderImages />} />
      <Route path="videos" element={<Videos />} />

      {/*<Route path="forbidden" element={<NotAllowed />} />
  <Route path="*" element={<NotFound />} />*/}
    </Routes>
  );
};
