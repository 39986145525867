import { Breadcrumb, Layout } from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getAboutRequest } from "../../redux-state/about/about.action";
import AboutUsForm from "./components/AboutUsForm";

const { Content } = Layout;

const AboutUs = (props) => {
  const { getAboutRequest, aboutLoaded } = props;
  useEffect(() => {
    getAboutRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAboutRequest]);

  return (
    <Content
      style={{
        margin: "0 16px",
      }}
    >
      <Breadcrumb
        style={{
          margin: "16px 0",
        }}
      >
        <Breadcrumb.Item>About Us</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{
          padding: 24,
          minHeight: 360,
        }}
      >
        {aboutLoaded ? <AboutUsForm /> : ""}
      </div>
    </Content>
  );
};

const mapStateToProps = (state) => {
  return {
    aboutLoaded: state.about.isLoaded,
  };
};
export default connect(mapStateToProps, {
  getAboutRequest,
})(AboutUs);
