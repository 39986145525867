import CategoriesActionTypes from "./categories.types";

export const getCategoriesRequest = () => ({
  type: CategoriesActionTypes.FETCH_CATEGORIES_REQUEST,
});

export const getCategoriesSuccess = (data) => ({
  type: CategoriesActionTypes.FETCH_CATEGORIES,
  payload: data,
});

export const getCategoriesFailed = (error) => ({
  type: CategoriesActionTypes.FETCH_CATEGORIES,
  payload: error,
});

export const updateCategoriesRequest = (data) => ({
  type: CategoriesActionTypes.FETCH_UPDATE_CATEGORIES_REQUEST,
  payload: data,
});

export const updateCategoriesSuccess = (data) => ({
  type: CategoriesActionTypes.FETCH_UPDATE_CATEGORIES_SUCCESS,
  payload: data,
});

export const updateCategoriesFailed = (error) => ({
  type: CategoriesActionTypes.FETCH_UPDATE_CATEGORIES_FAILED,
  payload: error,
});

export const deleteCategoriesRequest = (data) => ({
  type: CategoriesActionTypes.FETCH_DELETE_CATEGORIES_REQUEST,
  payload: data,
});

export const deleteCategoriesSuccess = (data) => ({
  type: CategoriesActionTypes.FETCH_DELETE_CATEGORIES_SUCCESS,
  payload: data,
});

export const deleteCategoriesFailed = (error) => ({
  type: CategoriesActionTypes.FETCH_DELETE_CATEGORIES_FAILED,
  payload: error,
});

export const editCategoriesRequest = (data, id) => ({
  type: CategoriesActionTypes.FETCH_EDIT_CATEGORIES_REQUEST,
  payload: data,
  id: id,
});

export const editCategoriesSuccess = (data, id) => ({
  type: CategoriesActionTypes.FETCH_EDIT_CATEGORIES_SUCCESS,
  payload: data,
  id: id,
});

export const editCategoriesFailed = (error) => ({
  type: CategoriesActionTypes.FETCH_EDIT_CATEGORIES_FAILED,
  payload: error,
});
