import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import React, {
  forwardRef,
  useImperativeHandle,
  useLayoutEffect,
  useState,
} from "react";
import {
  ref,
  getDownloadURL,
  deleteObject,
  uploadBytesResumable,
} from "firebase/storage";
import { storage } from "../../firebase-utils/firebase-utils";
import "./Upload.styles.css";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const UploadImage = forwardRef(({ images, maxImagesNo, tabFolder }, _ref) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [notImage, setNotImage] = useState("");
  const [progressPercent, setProgressPercent] = useState(0);

  useImperativeHandle(
    _ref,
    () => ({
      getMyState: () => {
        return fileList;
      },
    }),
    [fileList]
  );

  useLayoutEffect(() => {
    setFileList(
      images.map((image) => ({
        uid: image.uid,
        name: image.name,
        status: "done",
        url: image.url,
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ file: newFiles }) => {
    const newFile = newFiles.originFileObj;

    const isPNG = newFiles.type === "image/png";
    const isJPG = newFiles.type === "image/jpeg";
    if (newFiles.status !== "removed") {
      if (isPNG || isJPG) {
        const storageRef = ref(storage, `${tabFolder}/${newFile.name}`);
        const uploadTask = uploadBytesResumable(storageRef, newFile, {
          contentType: newFile.type,
        });
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgressPercent(progress);
          },
          (error) => {
            alert(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              const id = Math.max(...images.map((o) => o.uid)) + 1;
              setFileList([
                ...fileList,
                {
                  uid: id,
                  name: newFile.name,
                  status: "done",
                  url: downloadURL,
                },
              ]);
            });
          }
        );
      } else {
        setNotImage("Please enter valid jpeg/png image");
      }
    } else {
      const pictureRef = ref(storage, `${tabFolder}/${newFiles.name}`);
      deleteObject(pictureRef)
        .then(() => {
          setFileList([
            ...fileList.filter((item) => item.uid !== newFiles.uid),
          ]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <span className="error">{notImage !== "" ? notImage : ""}</span>
      <Upload
        action={fileList}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        progress={progressPercent}
      >
        {maxImagesNo && fileList.length >= maxImagesNo ? null : uploadButton}
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </React.Fragment>
  );
});
export default React.memo(UploadImage);
