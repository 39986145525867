import { Button, Col, Form, Input, Row, Typography } from "antd";
import { doc, setDoc } from "firebase/firestore";
import React, { useLayoutEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { db } from "../../../firebase-utils/firebase-utils";
import { updateAboutRequest } from "../../../redux-state/about/about.action";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
};

const { Text } = Typography;

const FooterForm = (props) => {
  const { footerData, footerLoaded } = props;
  const [message, setMessage] = useState("");
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const finnishHandler = () => {
    const data = form.getFieldsValue();
    const docRef = doc(db, "footer", "gbMUPJMSdbD8PutruUEZ");
    setDoc(docRef, data)
      .then(() => {
        dispatch(updateAboutRequest(data));
        setMessage("Updated Successfully!");
      })
      .catch((error) => {
        setMessage("Server connection failed");
      });
  };

  useLayoutEffect(() => {
    form.setFieldsValue(footerData); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (footerLoaded)
    return (
      <Form
        {...layout}
        name="nest-messages"
        validateMessages={validateMessages}
        form={form}
      >
        <Form.Item
          name={["title"]}
          label="Title"
          rules={[
            {
              required: true,
              type: "text",
            },
          ]}
          style={{ maxWidth: "500px" }}
        >
          <Input placeholder="Add footer title..." maxLength={40} />
        </Form.Item>
        <Form.Item
          name={["discord"]}
          label="Discord"
          rules={[
            {
              required: true,
              type: "text",
            },
          ]}
          style={{ maxWidth: "500px" }}
        >
          <Input placeholder="Add discord link..." />
        </Form.Item>
        <Row>
          <Col offset={2}>
            <Form.Item
              wrapperCol={{
                ...layout.wrapperCol,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => finnishHandler()}
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
          <Col span={8} offset={2}>
            <Form.Item
              wrapperCol={{
                ...layout.wrapperCol,
              }}
            >
              {message !== "" && message !== "Server connection failed" ? (
                <Text type="success">{message}</Text>
              ) : (
                <Text type="danger">{message}</Text>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
};

const mapStateToProps = (state) => {
  return {
    footerData: state.footer.footer[0],
    footerLoaded: state.footer.isLoaded,
  };
};
export default connect(mapStateToProps)(FooterForm);
