import { Breadcrumb, Layout } from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getCategoriesRequest } from "../../redux-state/categories/categories.action";
import TableData from "./table/Table";
const { Content } = Layout;

const Categories = (props) => {
  const { getCategoriesRequest, categoriesLoaded } = props;

  useEffect(() => {
    getCategoriesRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCategoriesRequest]);

  const tableDatas = [
    {
      title: "Id",
      dataIndex: "id",
      width: "25%",
      editable: false,
    },
    {
      title: "Category",
      dataIndex: "title",
      width: "25%",
      editable: true,
    },
  ];

  return (
    <Content
      style={{
        margin: "0 16px",
      }}
    >
      <Breadcrumb
        style={{
          margin: "16px 0",
        }}
      >
        <Breadcrumb.Item>Categories</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{
          padding: 24,
          minHeight: 360,
        }}
      >
        {categoriesLoaded ? <TableData tableDatas={tableDatas} /> : ""}
      </div>
    </Content>
  );
};

const mapStateToProps = (state) => {
  return {
    categoriesLoaded: state.categories.isLoaded,
  };
};
export default connect(mapStateToProps, {
  getCategoriesRequest,
})(Categories);
