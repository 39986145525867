import { Button, Col, Form, Input, InputNumber, Row, Typography } from "antd";
import { doc, setDoc } from "firebase/firestore";
import React, { useLayoutEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";

import { UploadImage } from "../../../components";
import { db } from "../../../firebase-utils/firebase-utils";
import { updateAboutRequest } from "../../../redux-state/about/about.action";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  image: "${label} are required!",
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
/* eslint-enable no-template-curly-in-string */

const { Text } = Typography;

const AboutUsForm = (props) => {
  const { aboutData, aboutLoaded } = props;
  const [message, setMessage] = useState("");
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const imageRef = useRef();
  const subImageRef = useRef();

  const finnishHandler = () => {
    const images =
      imageRef.current !== undefined ? imageRef.current.getMyState() : "";
    const subImages =
      subImageRef.current !== undefined ? subImageRef.current.getMyState() : "";
    const data = form.getFieldsValue();
    const newData = {
      nrManagers: data.nrManagers,
      description: data.description,
      images: images,
      subdescription: data.subdescription,
      subImages: subImages,
    };
    const docRef = doc(db, "about", "PhCG0v0DEkRZno82kn9m");
    setDoc(docRef, newData)
      .then(() => {
        dispatch(updateAboutRequest(newData));
        setMessage("Updated Successfully!");
      })
      .catch((error) => {
        setMessage("Server connection failed");
      });
  };

  useLayoutEffect(() => {
    form.setFieldsValue(aboutData); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (aboutLoaded)
    return (
      <Form
        {...layout}
        name="nest-messages"
        validateMessages={validateMessages}
        form={form}
      >
        <Form.Item
          name={["nrManagers"]}
          label="Number of managers"
          rules={[
            {
              required: true,
              type: "number",
              min: 0,
              max: 9999,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name={["images"]}
          label="Images"
          rules={[
            {
              required: true,
              message: "Please choose some images",
            },
          ]}
        >
          <UploadImage
            images={aboutData.images}
            ref={imageRef}
            maxImagesNo={2}
            tabFolder={"about"}
          />
        </Form.Item>
        <Form.Item
          name={["description"]}
          label="Description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.TextArea style={{ width: 500, height: 200 }} />
        </Form.Item>
        <Form.Item
          name={["subImages"]}
          label="SubImages"
          rules={[
            {
              required: true,
              message: "Please choose some sub-images",
            },
          ]}
        >
          <UploadImage
            images={aboutData.subImages}
            ref={subImageRef}
            maxImagesNo={3}
            tabFolder={"about"}
          />
        </Form.Item>
        <Form.Item
          name={["subdescription"]}
          label="Sub description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.TextArea style={{ width: 500, height: 200 }} />
        </Form.Item>

        <Row>
          <Col offset={8}>
            <Form.Item
              wrapperCol={{
                ...layout.wrapperCol,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => finnishHandler()}
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
          <Col span={8} offset={4}>
            <Form.Item
              wrapperCol={{
                ...layout.wrapperCol,
              }}
            >
              {message !== "" && message !== "Server connection failed" ? (
                <Text type="success">{message}</Text>
              ) : (
                <Text type="danger">{message}</Text>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
};

const mapStateToProps = (state) => {
  return {
    aboutData: state.about.about[0],
    aboutLoaded: state.about.isLoaded,
  };
};
export default connect(mapStateToProps)(AboutUsForm);
