import { getAboutFromFirebase } from "../../firebase-utils/firebase-utils";
import { put, takeEvery, fork } from "redux-saga/effects";
import {
  getAboutSuccess,
  getAboutFailed,
  updateAboutSuccess,
  updateAboutFailed,
} from "./about.action";
import AboutActionTypes from "./about.types";

function* fetchAboutAsync() {
  try {
    const about = getAboutFromFirebase();
    yield put(getAboutSuccess(about));
  } catch (error) {
    yield put(getAboutFailed(error.message));
  }
}

function* fetchAboutStart() {
  yield takeEvery(AboutActionTypes.FETCH_ABOUT_REQUEST, fetchAboutAsync);
}

function* fetchUpdateAboutAsync({ payload }) {
  try {
    yield put(updateAboutSuccess(payload));
  } catch (error) {
    yield put(updateAboutFailed(error.message));
  }
}

function* fetchAboutUpdateStart() {
  yield takeEvery(
    AboutActionTypes.FETCH_UPDATE_ABOUT_REQUEST,
    fetchUpdateAboutAsync
  );
}

const AboutSaga = [fork(fetchAboutStart), fork(fetchAboutUpdateStart)];

export default AboutSaga;
