const aboutActionTypes = {
  FETCH_ABOUT_REQUEST: "FETCH_ABOUT_REQUEST",
  FETCH_ABOUT: "FETCH_ABOUT",
  FETCH_ABOUT_FULFILLED: "FETCH_ABOUT_FULFILLED",
  FETCH_ABOUT_REJECTED: "FETCH_ABOUT_REJECTED",
  FETCH_UPDATE_ABOUT_REQUEST: "FETCH_UPDATE_ABOUT_REQUEST",
  FETCH_UPDATE_ABOUT_SUCCESS: "FETCH_UPDATE_ABOUT_SUCCESS",
  FETCH_UPDATE_ABOUT_FAILED: "FETCH_UPDATE_ABOUT_FAILED",
};

export default aboutActionTypes;
