import { Button, Col, Form, Input, Row, Typography } from "antd";
import { doc, setDoc } from "firebase/firestore";
import React, { useLayoutEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";

import { UploadImage } from "../../../components";
import { db } from "../../../firebase-utils/firebase-utils";
import { updateHeaderRequest } from "../../../redux-state/header/header.action";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  st1: "${label} is required!",
  st2: "${label} are required!",
  st3: "${label} are required!",
};
/* eslint-enable no-template-curly-in-string */

const { Text } = Typography;

const HeaderImagesForm = (props) => {
  const { headerImagesData, headerImagesLoaded } = props;
  const [message, setMessage] = useState("");
  const [headerTitle, setHeaderTitle] = useState(headerImagesData.title);

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const logoImageRef = useRef();
  const st1ImageRef = useRef();
  const st2ImageRef = useRef();
  const st3ImageRef = useRef();

  const finnishHandler = () => {
    const logoImage =
      logoImageRef.current !== undefined
        ? logoImageRef.current.getMyState()
        : "";
    const st1Image =
      st1ImageRef.current !== undefined ? st1ImageRef.current.getMyState() : "";
    const st2Image =
      st2ImageRef.current !== undefined ? st2ImageRef.current.getMyState() : "";
    const st3Image =
      st3ImageRef.current !== undefined ? st3ImageRef.current.getMyState() : "";
    const newData = {
      st1: st1Image,
      st2: st2Image,
      st3: st3Image,
      logo: logoImage[0],
      title: headerTitle,
    };

    const docRef = doc(db, "header", "98qk1bWBtStOy5shkhyg");
    setDoc(docRef, newData)
      .then(() => {
        dispatch(updateHeaderRequest(newData));
        setMessage("Updated Successfully!");
      })
      .catch((error) => {
        setMessage("Server connection failed");
      });
  };

  useLayoutEffect(() => {
    form.setFieldsValue(headerImagesData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (headerImagesLoaded)
    return (
      <Form
        {...layout}
        name="nest-messages"
        validateMessages={validateMessages}
        form={form}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Form.Item
          name={["logo"]}
          label="Logo"
          rules={[
            {
              required: true,
              message: "Please choose logo image",
            },
          ]}
        >
          <UploadImage
            images={[headerImagesData.logo]}
            ref={logoImageRef}
            maxImagesNo={1}
            tabFolder={"header"}
          />
        </Form.Item>
        <Form.Item
          name={["title"]}
          label="Title"
          rules={[
            {
              required: true,
              type: "text",
            },
          ]}
          style={{ maxWidth: "500px", minWidth: "400px" }}
        >
          <Input
            placeholder="Add header title..."
            maxLength={40}
            onChange={(e) => setHeaderTitle(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name={["st1"]}
          label="Images First Column"
          rules={[
            {
              required: true,
              message: "Please choose some images",
            },
          ]}
        >
          <UploadImage
            images={headerImagesData.st1}
            ref={st1ImageRef}
            maxImagesNo={4}
            tabFolder={"header"}
          />
        </Form.Item>
        <Form.Item
          name={["st2"]}
          label="Images Second Column"
          rules={[
            {
              required: true,
              message: "Please choose some images",
            },
          ]}
        >
          <UploadImage
            images={headerImagesData.st2}
            ref={st2ImageRef}
            maxImagesNo={4}
            tabFolder={"header"}
          />
        </Form.Item>
        <Form.Item
          name={["st3"]}
          label="Images Third Column"
          rules={[
            {
              required: true,
              message: "Please choose some images",
            },
          ]}
        >
          <UploadImage
            images={headerImagesData.st3}
            ref={st3ImageRef}
            maxImagesNo={4}
            tabFolder={"header"}
          />
        </Form.Item>

        <Row>
          <Col offset={8}>
            <Form.Item
              wrapperCol={{
                ...layout.wrapperCol,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => finnishHandler()}
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
          <Col span={8} offset={4}>
            <Form.Item
              wrapperCol={{
                ...layout.wrapperCol,
              }}
            >
              {message !== "" && message !== "Server connection failed" ? (
                <Text type="success">{message}</Text>
              ) : (
                <Text type="danger">{message}</Text>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
};

const mapStateToProps = (state) => {
  return {
    headerImagesData: state.header.header[0],
    headerImagesLoaded: state.header.isLoaded,
  };
};
export default connect(mapStateToProps)(HeaderImagesForm);
