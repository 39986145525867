const headerActionTypes = {
  FETCH_HEADER_REQUEST: "FETCH_HEADER_REQUEST",
  FETCH_HEADER: "FETCH_HEADER",
  FETCH_HEADER_FULFILLED: "FETCH_HEADER_FULFILLED",
  FETCH_HEADER_REJECTED: "FETCH_HEADER_REJECTED",
  FETCH_UPDATE_HEADER_REQUEST: "  FETCH_UPDATE_HEADER_REQUEST",
  FETCH_UPDATE_HEADER_SUCCESS: "FETCH_UPDATE_HEADER_SUCCESS",
  FETCH_UPDATE_HEADER_FAILED: "FETCH_UPDATE_HEADER_FAILED",
};

export default headerActionTypes;
