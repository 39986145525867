import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBKhoYBvhhwCIqJKlFcKjEcSPKngcDgaPM",
  authDomain: "video-dawn.firebaseapp.com",
  databaseURL:
    "https://video-dawn-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "video-dawn",
  storageBucket: "video-dawn.appspot.com",
  messagingSenderId: "315097540207",
  appId: "1:315097540207:web:facbae7786e8678f540f9a",
  measurementId: "G-JQ28E99000",
};

//const firebaseConfig = {
//  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
//  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
//  appId: process.env.REACT_APP_FIREBASE_APP_ID,
//  measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID,
//};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);

const categoriesCollectionRef = collection(db, "categories");

export const getCategoriesFromFirebase = async () => {
  const categories = await getDocs(categoriesCollectionRef);
  const data = categories.docs.map((doc) => doc.data());
  return data;
};

const videosCollectionRef = collection(db, "videos");

export const getVideosFromFirebase = async () => {
  const videos = await getDocs(videosCollectionRef);
  const data = videos.docs.map((doc) => doc.data());
  return data;
};

const headerCollectionRef = collection(db, "header");

export const getHeaderFromFirebase = async () => {
  const header = await getDocs(headerCollectionRef);
  const data = header.docs.map((doc) => doc.data());
  return data;
};

const aboutCollectionRef = collection(db, "about");

export const getAboutFromFirebase = async () => {
  const about = await getDocs(aboutCollectionRef);
  const data = about.docs.map((doc) => doc.data());
  return data;
};

const footerCollectionRef = collection(db, "footer");

export const getFooterFromFirebase = async () => {
  const footer = await getDocs(footerCollectionRef);
  const data = footer.docs.map((doc) => doc.data());
  return data;
};
