import VideosActionTypes from "./videos.types";

export const getVideosRequest = () => ({
  type: VideosActionTypes.FETCH_VIDEOS_REQUEST,
});

export const getVideosSuccess = (data) => ({
  type: VideosActionTypes.FETCH_VIDEOS,
  payload: data,
});

export const getVideosFailed = (error) => ({
  type: VideosActionTypes.FETCH_VIDEOS,
  payload: error,
});

export const updateVideosRequest = (data) => ({
  type: VideosActionTypes.FETCH_UPDATE_VIDEOS_REQUEST,
  payload: data,
});

export const updateVideosSuccess = (data) => ({
  type: VideosActionTypes.FETCH_UPDATE_VIDEOS_SUCCESS,
  payload: data,
});

export const updateVideosFailed = (error) => ({
  type: VideosActionTypes.FETCH_UPDATE_VIDEOS_FAILED,
  payload: error,
});

export const deleteVideosRequest = (data) => ({
  type: VideosActionTypes.FETCH_DELETE_VIDEOS_REQUEST,
  payload: data,
});

export const deleteVideosSuccess = (data) => ({
  type: VideosActionTypes.FETCH_DELETE_VIDEOS_SUCCESS,
  payload: data,
});

export const deleteVideosFailed = (error) => ({
  type: VideosActionTypes.FETCH_DELETE_VIDEOS_FAILED,
  payload: error,
});

export const editVideosRequest = (data, id) => ({
  type: VideosActionTypes.FETCH_EDIT_VIDEOS_REQUEST,
  payload: data,
  id: id,
});

export const editVideosSuccess = (data, id) => ({
  type: VideosActionTypes.FETCH_EDIT_VIDEOS_SUCCESS,
  payload: data,
  id: id,
});

export const editVideosFailed = (error) => ({
  type: VideosActionTypes.FETCH_EDIT_VIDEOS_FAILED,
  payload: error,
});
