export const editCategory = (existingCategories, category, id) => {
  console.log(id);
  const categories = existingCategories.filter(
    (category) => category.id !== id
  );
  console.log(categories);
  return [
    ...categories,
    {
      id: id,
      key: id,
      title: category.title,
    },
  ];
};
