import { Form, Input, InputNumber, Popconfirm, Table, Typography } from "antd";
import React, { useState } from "react";
import {
  collection,
  doc,
  updateDoc,
  query,
  where,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { connect, useDispatch } from "react-redux";
import ReactPlayer from "react-player";

import { db } from "../../../firebase-utils/firebase-utils";
import {
  deleteVideosRequest,
  editVideosRequest,
} from "../../../redux-state/videos/videos.action";

import "./Table.styles.css";

const { Text } = Typography;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const TableMovieData = (props) => {
  const { videosData, tableDatas } = props;

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [message, setMessage] = useState("");

  const isEditing = (record) => record.key === editingKey;

  const dispatch = useDispatch();

  const edit = async (record) => {
    form.setFieldsValue({
      id: "",
      title: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const handleDelete = async (key) => {
    const newData = videosData.filter((item) => item.key !== key);
    const q = query(collection(db, "videos"), where("id", "==", key));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (docu) => {
      const deleteDocRef = doc(db, "videos", `${docu.id}`);
      deleteDoc(deleteDocRef)
        .then(() => {
          dispatch(deleteVideosRequest(newData));
          setMessage("Video Added Successfully!");
        })
        .catch((error) => {
          setMessage("Server connection failed");
        });
    });
  };

  const saveData = async (key, newData) => {
    const q = query(collection(db, "videos"), where("id", "==", key));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (docu) => {
      const editDocRef = doc(db, "videos", `${docu.id}`);
      updateDoc(editDocRef, newData)
        .then(() => {
          dispatch(editVideosRequest(newData, key));
          setMessage("Video Saved Successfully!");
        })
        .catch((error) => {
          setMessage("Server connection failed");
        });
    });
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const index = videosData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = videosData[index];
        videosData.splice(index, 1, {
          ...item,
          ...row,
        });
        saveData(key, {
          url: row.url,
          category: Number(row.category),
          show: row.show,
        });
        setEditingKey("");
      } else {
        //saveData(key, row);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    tableDatas[0],
    {
      title: "Video",
      key: "url",
      dataIndex: "url",
      render: (_, { url }) => (
        <ReactPlayer url={url} playing={false} width="100px" height="100px" />
      ),
    },
    tableDatas[2],
    tableDatas[3],
    tableDatas[4],
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <Typography.Link>Cancel</Typography.Link>
            </Popconfirm>
          </span>
        ) : (
          <div style={{ display: "flex", gap: "10px" }}>
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
            {videosData.length >= 1 ? (
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => handleDelete(record.key)}
              >
                <Typography.Link>Delete</Typography.Link>
              </Popconfirm>
            ) : null}
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <div>
        <Form.Item>
          {message !== "" && message !== "Server connection failed" ? (
            <Text type="success">{message}</Text>
          ) : (
            <Text type="danger">{message}</Text>
          )}
        </Form.Item>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={videosData.sort((a, b) => a.id - b.id)}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
        />
      </div>
    </Form>
  );
};
const mapStateToProps = (state) => {
  return {
    videosData: state.videos.videos,
  };
};
export default connect(mapStateToProps)(TableMovieData);
