const categoriesActionTypes = {
  FETCH_CATEGORIES_REQUEST: "FETCH_CATEGORIES_REQUEST",
  FETCH_CATEGORIES: "FETCH_CATEGORIES",
  FETCH_CATEGORIES_FULFILLED: "FETCH_CATEGORIES_FULFILLED",
  FETCH_CATEGORIES_REJECTED: "FETCH_CATEGORIES_REJECTED",
  FETCH_UPDATE_CATEGORIES_REQUEST: "FETCH_UPDATE_CATEGORIES_REQUEST",
  FETCH_UPDATE_CATEGORIES_SUCCESS: "FETCH_UPDATE_CATEGORIES_SUCCESS",
  FETCH_UPDATE_CATEGORIES_FAILED: "FETCH_UPDATE_CATEGORIES_FAILED",
  FETCH_DELETE_CATEGORIES_REQUEST: "FETCH_DELETE_CATEGORIES_REQUEST",
  FETCH_DELETE_CATEGORIES_SUCCESS: "FETCH_DELETE_CATEGORIES_SUCCESS",
  FETCH_DELETE_CATEGORIES_FAILED: "FETCH_DELETE_CATEGORIES_FAILED",
  FETCH_EDIT_CATEGORIES_REQUEST: "FETCH_EDIT_CATEGORIES_REQUEST",
  FETCH_EDIT_CATEGORIES_SUCCESS: "FETCH_EDIT_CATEGORIES_SUCCESS",
  FETCH_EDIT_CATEGORIES_FAILED: "FETCH_EDIT_CATEGORIES_FAILED",
};

export default categoriesActionTypes;
