import { Breadcrumb, Layout, Row } from "antd";
import React from "react";
import LoginForm from "./login-form/LoginForm";

const { Content } = Layout;

const Login = ({ setUser, setIsLoggedIn }) => {
  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ minHeight: "100vh", margin: "0 100px" }}
    >
      <Content
        style={{
          maxWidth: "400px",
          height: "400px",
          padding: "50px",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        }}
      >
        <Breadcrumb
          style={{
            margin: "16px 0",
          }}
        ></Breadcrumb>
        <div
          className="site-layout-background centered"
          style={{
            padding: 24,
            minHeight: 360,
          }}
        >
          <LoginForm setUser={setUser} setIsLoggedIn={setIsLoggedIn} />
        </div>
      </Content>
    </Row>
  );
};

export default Login;
