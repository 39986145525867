import { getHeaderFromFirebase } from "../../firebase-utils/firebase-utils";
import { put, takeEvery, fork } from "redux-saga/effects";
import {
  getHeaderSuccess,
  getHeaderFailed,
  updateHeaderSuccess,
  updateHeaderFailed,
} from "./header.action";
import HeaderActionTypes from "./header.types";

function* fetchHeaderAsync() {
  try {
    const header = getHeaderFromFirebase();
    yield put(getHeaderSuccess(header));
  } catch (error) {
    yield put(getHeaderFailed(error.message));
  }
}

function* fetchHeaderStart() {
  yield takeEvery(HeaderActionTypes.FETCH_HEADER_REQUEST, fetchHeaderAsync);
}

function* fetchUpdateHeaderAsync({ payload }) {
  try {
    yield put(updateHeaderSuccess(payload));
  } catch (error) {
    yield put(updateHeaderFailed(error.message));
  }
}

function* fetchHeaderUpdateStart() {
  yield takeEvery(
    HeaderActionTypes.FETCH_UPDATE_HEADER_REQUEST,
    fetchUpdateHeaderAsync
  );
}

const HeaderSaga = [fork(fetchHeaderStart), fork(fetchHeaderUpdateStart)];

export default HeaderSaga;
