import HeaderActionTypes from "./header.types";

export const getHeaderRequest = () => ({
  type: HeaderActionTypes.FETCH_HEADER_REQUEST,
});

export const getHeaderSuccess = (data) => ({
  type: HeaderActionTypes.FETCH_HEADER,
  payload: data,
});

export const getHeaderFailed = (error) => ({
  type: HeaderActionTypes.FETCH_HEADER,
  payload: error,
});

export const updateHeaderRequest = (data) => ({
  type: HeaderActionTypes.FETCH_UPDATE_HEADER_REQUEST,
  payload: data,
});

export const updateHeaderSuccess = (data) => ({
  type: HeaderActionTypes.FETCH_UPDATE_HEADER_SUCCESS,
  payload: data,
});

export const updateHeaderFailed = (error) => ({
  type: HeaderActionTypes.FETCH_UPDATE_HEADER_FAILED,
  payload: error,
});
