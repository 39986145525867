import {
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
} from "antd";
import React, { useState } from "react";
import {
  addDoc,
  collection,
  doc,
  updateDoc,
  query,
  where,
  getDocs,
  deleteDoc,
} from "firebase/firestore";

import "./Table.styles.css";
import { db } from "../../../firebase-utils/firebase-utils";
import { connect, useDispatch } from "react-redux";
import {
  deleteCategoriesRequest,
  editCategoriesRequest,
  updateCategoriesRequest,
} from "../../../redux-state/categories/categories.action";

const { Text } = Typography;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const TableData = (props) => {
  const { categoriesData, tableDatas } = props;

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [category, setCategory] = useState("");
  const [message, setMessage] = useState("");

  const isEditing = (record) => record.key === editingKey;

  const dispatch = useDispatch();

  const edit = async (record) => {
    form.setFieldsValue({
      id: "",
      title: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const handleDelete = async (key) => {
    const newData = categoriesData.filter((item) => item.key !== key);
    const q = query(collection(db, "categories"), where("id", "==", key));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (docu) => {
      const deleteDocRef = doc(db, "categories", `${docu.id}`);
      deleteDoc(deleteDocRef)
        .then(() => {
          dispatch(deleteCategoriesRequest(newData));
          setMessage("Category Added Successfully!");
        })
        .catch((error) => {
          setMessage("Server connection failed");
        });
    });
  };

  const handleAdd = async () => {
    const existingCategories = categoriesData.find(
      (item) => item.title === category
    );

    if (category !== "" && !existingCategories) {
      const id = Math.max(...categoriesData.map((o) => o.id)) + 1;
      const newData = {
        key: id,
        id: id,
        title: category,
      };
      await addDoc(collection(db, "categories"), newData)
        .then(() => {
          dispatch(updateCategoriesRequest(newData));
          setMessage("Category Added Successfully!");
        })
        .catch((error) => {
          setMessage("Server connection failed");
        });
    }
  };

  const saveData = async (key, newData) => {
    const q = query(collection(db, "categories"), where("id", "==", key));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (docu) => {
      const editDocRef = doc(db, "categories", `${docu.id}`);
      updateDoc(editDocRef, newData)
        .then(() => {
          dispatch(editCategoriesRequest(newData, key));
          setMessage("Category Saved Successfully!");
        })
        .catch((error) => {
          setMessage("Server connection failed");
        });
    });
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const index = categoriesData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = categoriesData[index];
        categoriesData.splice(index, 1, {
          ...item,
          ...row,
        });
        saveData(key, row);
        setEditingKey("");
      } else {
        //saveData(key, row);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    tableDatas[0],
    tableDatas[1],
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <Typography.Link>Cancel</Typography.Link>
            </Popconfirm>
          </span>
        ) : (
          <div style={{ display: "flex", gap: "10px" }}>
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
            {categoriesData.length >= 1 ? (
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => handleDelete(record.key)}
              >
                <Typography.Link>Delete</Typography.Link>
              </Popconfirm>
            ) : null}
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <div>
        <Form.Item>
          {message !== "" && message !== "Server connection failed" ? (
            <Text type="success">{message}</Text>
          ) : (
            <Text type="danger">{message}</Text>
          )}
        </Form.Item>
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            onClick={handleAdd}
            type="primary"
            style={{
              marginBottom: 16,
            }}
          >
            Add a category
          </Button>
          <Input
            placeholder="Add category name"
            style={{ maxHeight: "32px", maxWidth: "200px" }}
            maxLength={10}
            onChange={(e) => setCategory(e.target.value)}
          />
        </div>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={categoriesData.sort((a, b) => a.id - b.id)}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
        />
      </div>
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    categoriesData: state.categories.categories,
  };
};
export default connect(mapStateToProps)(TableData);
