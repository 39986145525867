import { Breadcrumb, Layout } from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import HeaderImagesForm from "./components/HeaderImagesForm";
import { getHeaderRequest } from "../../redux-state/header/header.action";

const { Content } = Layout;

const HeaderImages = (props) => {
  const { getHeaderRequest, headerImagesLoaded } = props;

  useEffect(() => {
    getHeaderRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getHeaderRequest]);

  return (
    <Content
      style={{
        margin: "0 16px",
      }}
    >
      <Breadcrumb
        style={{
          margin: "16px 0",
        }}
      >
        <Breadcrumb.Item>Header Images</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{
          padding: 24,
          minHeight: 360,
        }}
      >
        {headerImagesLoaded ? <HeaderImagesForm /> : ""}
      </div>
    </Content>
  );
};

const mapStateToProps = (state) => {
  return {
    headerImagesLoaded: state.header.isLoaded,
  };
};
export default connect(mapStateToProps, {
  getHeaderRequest,
})(HeaderImages);
