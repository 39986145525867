import { getVideosFromFirebase } from "../../firebase-utils/firebase-utils";
import { put, takeEvery, fork } from "redux-saga/effects";
import {
  getVideosSuccess,
  getVideosFailed,
  updateVideosSuccess,
  updateVideosFailed,
  deleteVideosSuccess,
  deleteVideosFailed,
  editVideosSuccess,
  editVideosFailed,
} from "./videos.action";
import VideosActionTypes from "./videos.types";

function* fetchVideosAsync() {
  try {
    const videos = getVideosFromFirebase();
    yield put(getVideosSuccess(videos));
  } catch (error) {
    yield put(getVideosFailed(error.message));
  }
}

function* fetchVideosStart() {
  yield takeEvery(VideosActionTypes.FETCH_VIDEOS_REQUEST, fetchVideosAsync);
}

function* fetchUpdateVideosAsync({ payload }) {
  try {
    yield put(updateVideosSuccess(payload));
  } catch (error) {
    yield put(updateVideosFailed(error.message));
  }
}

function* fetchVideosUpdateStart() {
  yield takeEvery(
    VideosActionTypes.FETCH_UPDATE_VIDEOS_REQUEST,
    fetchUpdateVideosAsync
  );
}

function* fetchDeleteVideosAsync({ payload }) {
  try {
    yield put(deleteVideosSuccess(payload));
  } catch (error) {
    yield put(deleteVideosFailed(error.message));
  }
}

function* fetchVideosDeleteStart() {
  yield takeEvery(
    VideosActionTypes.FETCH_DELETE_VIDEOS_REQUEST,
    fetchDeleteVideosAsync
  );
}
function* fetchEditVideosAsync({ payload, id }) {
  console.log(id);
  try {
    yield put(editVideosSuccess(payload, id));
  } catch (error) {
    yield put(editVideosFailed(error.message));
  }
}

function* fetchVideosEditStart() {
  yield takeEvery(
    VideosActionTypes.FETCH_EDIT_VIDEOS_REQUEST,
    fetchEditVideosAsync
  );
}

const videosSaga = [
  fork(fetchVideosStart),
  fork(fetchVideosUpdateStart),
  fork(fetchVideosDeleteStart),
  fork(fetchVideosEditStart),
];

export default videosSaga;
