import { getCategoriesFromFirebase } from "../../firebase-utils/firebase-utils";
import { put, takeEvery, fork } from "redux-saga/effects";
import {
  getCategoriesSuccess,
  getCategoriesFailed,
  updateCategoriesSuccess,
  updateCategoriesFailed,
  deleteCategoriesSuccess,
  deleteCategoriesFailed,
  editCategoriesSuccess,
  editCategoriesFailed,
} from "./categories.action";
import CategoriesActionTypes from "./categories.types";

function* fetchCategoriesAsync() {
  try {
    const categories = getCategoriesFromFirebase();
    yield put(getCategoriesSuccess(categories));
  } catch (error) {
    yield put(getCategoriesFailed(error.message));
  }
}

function* fetchCategoriesStart() {
  yield takeEvery(
    CategoriesActionTypes.FETCH_CATEGORIES_REQUEST,
    fetchCategoriesAsync
  );
}

function* fetchUpdateCategoriesAsync({ payload }) {
  try {
    yield put(updateCategoriesSuccess(payload));
  } catch (error) {
    yield put(updateCategoriesFailed(error.message));
  }
}

function* fetchCategoriesUpdateStart() {
  yield takeEvery(
    CategoriesActionTypes.FETCH_UPDATE_CATEGORIES_REQUEST,
    fetchUpdateCategoriesAsync
  );
}

function* fetchDeleteCategoriesAsync({ payload }) {
  try {
    yield put(deleteCategoriesSuccess(payload));
  } catch (error) {
    yield put(deleteCategoriesFailed(error.message));
  }
}

function* fetchCategoriesDeleteStart() {
  yield takeEvery(
    CategoriesActionTypes.FETCH_DELETE_CATEGORIES_REQUEST,
    fetchDeleteCategoriesAsync
  );
}
function* fetchEditCategoriesAsync({ payload, id }) {
  console.log(id);
  try {
    yield put(editCategoriesSuccess(payload, id));
  } catch (error) {
    yield put(editCategoriesFailed(error.message));
  }
}

function* fetchCategoriesEditStart() {
  yield takeEvery(
    CategoriesActionTypes.FETCH_EDIT_CATEGORIES_REQUEST,
    fetchEditCategoriesAsync
  );
}

const categoriesSaga = [
  fork(fetchCategoriesStart),
  fork(fetchCategoriesUpdateStart),
  fork(fetchCategoriesDeleteStart),
  fork(fetchCategoriesEditStart),
];

export default categoriesSaga;
