import {
  AppstoreOutlined,
  RobotOutlined,
  UngroupOutlined,
  ShareAltOutlined,
  VideoCameraOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu } from "antd";
import { signOut } from "firebase/auth";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { auth } from "../../firebase-utils/firebase-utils";

import { Router } from "../../routes";
import "./Dashboard.styles.css";

const { Header, Footer, Sider } = Layout;

function getItem(label, key, icon, route, children) {
  return {
    key,
    icon,
    children,
    label,
    route,
  };
}
const items = [
  getItem("Dashboard", "1", <AppstoreOutlined />, "dashboard"),
  getItem("Header", "2", <UngroupOutlined />, "header"),
  getItem("About Us", "3", <SmileOutlined />, "about"),
  getItem("Categories", "4", <ShareAltOutlined />, "categories"),
  getItem("Videos", "5", <VideoCameraOutlined />, "videos"),
  getItem("Footer", "6", <RobotOutlined />, "footer"),
];

export const Dashboard = ({ user, setUser, setIsLoggedIn }) => {
  const [collapsed, setCollapsed] = useState(false);

  const onSignOut = async () => {
    signOut(auth)
      .then(() => {
        setUser("");
        setIsLoggedIn(false);
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className="logo" />
        <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
          {items.map((item) => (
            <Menu.Item key={item.key}>
              {!collapsed ? (
                <Link to={item.route}>
                  {item.icon} {item.label}
                </Link>
              ) : (
                <Link to={item.route}>{item.icon}</Link>
              )}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: "0 30px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            onClick={() => onSignOut()}
          >
            Sign Out
          </Button>
        </Header>
        <Router />
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          ©2022 Created by Arian Nimani with Ant Design
        </Footer>
      </Layout>
    </Layout>
  );
};
