import React, { useState } from "react";
import { Dashboard } from "./pages";
import Login from "./pages/auth/login/Login";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState("");

  return (
    <div className="App">
      {isLoggedIn ? (
        <Dashboard
          user={user}
          setIsLoggedIn={setIsLoggedIn}
          setUser={setUser}
        />
      ) : (
        <Login setUser={setUser} setIsLoggedIn={setIsLoggedIn} />
      )}
    </div>
  );
};

export default App;
