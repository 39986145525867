import React, { useLayoutEffect, useState } from "react";
import { Input, Modal, Select, Typography } from "antd";
import { addDoc, collection } from "firebase/firestore";
import { updateVideosRequest } from "../../../redux-state/videos/videos.action";
import { connect, useDispatch } from "react-redux";
import { db } from "../../../firebase-utils/firebase-utils";
import { getCategoriesRequest } from "../../../redux-state/categories/categories.action";
import { capitalizeFirstLetter } from "../../../utils/utils";

const { Text } = Typography;

const ModalForm = (props) => {
  const [videoLink, setVideoLink] = useState("");
  const [videoCategory, setVideoCategory] = useState("");
  const [videoShow, setVideoShow] = useState("");
  const [categories, setCategories] = useState([]);
  const [formMessage, setFormMessage] = useState("");

  const {
    categoriesData,
    isModalOpen,
    setIsModalOpen,
    videosData,
    setMessage,
    getCategoriesRequest,
  } = props;

  useLayoutEffect(() => {
    getCategoriesRequest();
    setCategories(
      categoriesData.map((category) => ({
        value: category.id,
        label: capitalizeFirstLetter(category.title),
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCategoriesRequest]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const dispatch = useDispatch();

  const handleOk = async (category) => {
    const existingVideos = videosData.find((item) => item.url === videoLink);

    if (
      videoLink !== "" &&
      videoCategory !== "" &&
      videoShow !== "" &&
      !existingVideos
    ) {
      const id = Math.max(...videosData.map((o) => o.id)) + 1;
      const newData = {
        key: id,
        id: id,
        category: Number(videoCategory),
        show: videoShow,
        url: videoLink,
      };

      await addDoc(collection(db, "videos"), newData)
        .then(() => {
          dispatch(updateVideosRequest(newData));

          setMessage("Video Added Successfully!");
        })
        .catch((error) => {
          setMessage("Server connection failed");
        });
      setIsModalOpen(false);
    }
    setFormMessage("Please fill all form data");
  };

  const categoryHandleChange = (value) => {
    setVideoCategory(value);
  };

  const showHandleChange = (value) => {
    setVideoShow(value);
  };

  return (
    <React.Fragment>
      <Modal
        title="Add a Video"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {formMessage !== "" ? <Text type="danger">{formMessage}</Text> : ""}
        <div
          style={{
            display: "flex",
            gap: "5px",
            marginBottom: "10px",
            alignItems: "center",
            width: "500px",
          }}
        >
          <Text style={{ minWidth: "100px" }}>Youtube Link</Text>
          <Input
            placeholder="Add video link"
            style={{
              maxHeight: "32px",
              maxWidth: "350px",
            }}
            onChange={(e) => setVideoLink(e.target.value)}
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: "5px",
            marginBottom: "10px",
            alignItems: "center",
          }}
        >
          <Text style={{ minWidth: "100px" }}>Category</Text>
          <Select
            defaultValue="Select a category"
            style={{ width: 160 }}
            onChange={categoryHandleChange}
            options={categories}
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: "5px",
            marginBottom: "10px",
            alignItems: "center",
          }}
        >
          <Text style={{ minWidth: "100px" }}>Show</Text>
          <Select
            defaultValue="Select visible value"
            style={{ width: 160 }}
            onChange={showHandleChange}
            options={[
              {
                value: true,
                label: "Yes",
              },
              {
                value: false,
                label: "No",
              },
            ]}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    categoriesData: state.categories.categories,
  };
};
export default connect(mapStateToProps, { getCategoriesRequest })(ModalForm);
