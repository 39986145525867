export const editVideo = (existingVideos, video, id) => {
  const videos = existingVideos.filter((video) => video.id !== id);
  return [
    ...videos,
    {
      id: id,
      key: id,
      url: video.url,
      show: video.show,
      category: video.category,
    },
  ];
};
